import { Delete as DeleteIcon } from '@mui/icons-material';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FileService } from "common/services/FileService";
import { AppFunctions } from "helpers/AppFunctions";
import { GUID } from "helpers/GuidFunction";
import { IControlProps } from "models/IControlProps";
import { RootState } from "store/store";
import Loader from "../Loader";
import { createObjectURLByBlob, previewOrDownloadBase64 } from 'common/utils/download-base-64';
import { uploadingFileExtension, FileSize, ThumbnailProps, generateImageThumbnail, FileUploadConfiguration, validateFileName } from 'common/utils/upload-file-helper';
import CaptureImage, { CameraRefType } from './CaptureImage';
import Thumbo from "thumbo";
import clsx from 'clsx';
import FileUploadUtility from 'common/utils/FileUploadUtility';
import CloseIcon from '@mui/icons-material/Close';
import { z } from 'zod';
import ImageIcon from '@mui/icons-material/Image';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Add as AddIcon } from '@mui/icons-material';

export const FileUploadModelSchema = z.object({
    id: z.string().nullish(),
    referenceId: z.string().nullish(),
    tenantId: z.string().nullish(),
    blobId: z.string().nullish(),
    moduleName: z.string().nullish(),
    createdBy: z.string().nullish(),
    createdDate: z.string().nullish(),
    updatedBy: z.string().nullish(),
    updatedDate: z.string().nullish(),
    updatedByName: z.string().nullish(),
    fileName: z.string(),
    fileType: z.string().nullish(),
    ext: z.string().nullish(),
    size: z.number(),
    fileContent: z.string(),
    thumbnail: z.string().nullish(),
    contentType: z.string(),
    lastModifiedDate: z.date(),
    url: z.string().nullish(),
    displayFileSize: z.string().nullish(),
    fileStatus: z.string().nullish(),
})

export type FileUploadModel = z.infer<typeof FileUploadModelSchema>;

export type FileUploadProps = IControlProps & {
    candelete?: boolean;
    acceptextensions?: string;
    filestobeuploaded?: FileUploadModel[];
    onfileuploaded?: (files: FileUploadModel[], unmodifiedFiles?: FileList) => void;
    ondelete?: (fileId: string) => void;
    hidePreviewAction?: boolean;
    hideDownloadAction?: boolean;
    hideFileSize?: boolean;
    multiple?: boolean;
    allowImageCapture?: boolean;
    mergeAllImages?: boolean;
    thumbnailProps?: ThumbnailProps;
    hasDocumentUploaded?: boolean;
    isShowUpload?: boolean;
    fileUploadConfiguration?: FileUploadConfiguration;
    isShowFileMappingModal?: boolean;
    isShowLoader?: boolean,
    hideFileName?: boolean,
    isShowUploaderBox?: boolean,
    buttonLabel?: boolean
    page?:string
}

export type FileUploadRefType = {
    setFiles: (files: FileUploadModel[]) => void;
}

const FileUpload = React.forwardRef<FileUploadRefType, FileUploadProps>((props, ref): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const loggedUser = useSelector((state: RootState) => state.login);
    const [isRequired, setIsRequired] = useState<boolean>(props.isrequired ?? false);
    const cameraRef = React.createRef<CameraRefType>();
    const [isOpen, setOpen] = useState(false);
    const [dropLabelText, setDropLabelText] = React.useState<string>('');
    const [files, setFiles] = React.useState<File[]>([]);
    const labelText = props.filestobeuploaded?.length == 0 ? props.fileUploadConfiguration?.hoverLabel : '';
    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }

    const dragEvents = {
        onDragEnter: (e: React.DragEvent<HTMLElement>) => {
            props.fileUploadConfiguration?.onDragEnter?.(e)
            stopDefaults(e)
            e.dataTransfer.setDragImage(new Image(), 0, 0);
            e.dataTransfer.dropEffect = 'none'
            setDropLabelText(props.fileUploadConfiguration?.dropLabel!)

        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e)
            setDropLabelText(props.fileUploadConfiguration?.dropLabel!)
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e)
            setDropLabelText('Uploading...')
            setFile(e);
            props.fileUploadConfiguration?.onDrop?.(e)
        },
    }

    useEffect(() => {
        setIsRequired(props.isrequired ?? false)
        setMyFiles(props.filestobeuploaded ?? []);
        setDropLabelText('');
        setFiles([]);
    }, [props.isrequired, props.filestobeuploaded])
    const [myFiles, setMyFiles] = useState<FileUploadModel[]>(props.filestobeuploaded ?? []);
    const [capturedImages, setCapturedImages] = useState<FileUploadModel[]>([]);

    React.useImperativeHandle(ref, () => ({
        setFiles: (files: FileUploadModel[]) => setMyFiles(files ?? [])
    }));

    useEffect(() => {
        if (props.thumbnailProps)
            Thumbo.init();
    }, []);

    const setFile = (e: any) => {
        let files: FileList = e.target.files || e.dataTransfer.files;

        let fileObjects: File[] = [];
        for (let i = 0; i < files.length; i++) {
            const file: File = files[i];
            fileObjects.push(file);
        }
        setFiles(fileObjects);
        Promise.all(fileObjects.map(file => {
            return fileToUploadPromise(file);
        })).then((uploadableFiles: FileUploadModel[]) => {
            setIsLoading(false);
            props.onfileuploaded?.(uploadableFiles, files);
        });
    }

    const fileToUploadPromise = (file: File): Promise<FileUploadModel> => {
        setIsLoading(true);
        return new Promise<FileUploadModel>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (data) => {
                const result = reader.result as string;
                const documentAsString = btoa(result);
                const model: FileUploadModel = {
                    referenceId: GUID.Empty(),
                    fileName: props.isShowFileMappingModal ? file.name : validateFileName(file.name, 0, props.filestobeuploaded!),
                    contentType: file.type,
                    ext: uploadingFileExtension(file.name),
                    fileContent: documentAsString,
                    lastModifiedDate: new Date(file.lastModified),
                    size: file.size,
                    url: URL.createObjectURL(file),
                    displayFileSize: FileSize({
                        bytes: file.size
                    }),

                    id: GUID.NewGUID(),
                    createdBy: loggedUser.userId,
                    tenantId: loggedUser.tenantId,
                }
                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'svg+xml', 'x-icon'].includes(model.ext!);

                if (props.thumbnailProps?.show && isImage) {
                    generateImageThumbnail(file, props.thumbnailProps, (base64: any) => {
                        model.thumbnail = base64
                        resolve(model);
                    });
                } else {
                    resolve(model);
                }
            };
            reader.onerror = () => {
                reader.abort();
                reject(`There was an issue in reading ${file.name}: ${reader.error?.message}`);
            };
            reader.readAsBinaryString(file);
        })
    }

    const previewOrDownloadFileAsync = async (blobContainer: string, blobId: string, isPreview: boolean = true) => {
        setIsLoading(true);
        await FileService.previewDocumentAsync(blobContainer, blobId).then(response => {
            setIsLoading(false);
            previewOrDownloadBase64(response.fileContent, response.contentType, response.fileName, isPreview);
        }, error => {
            setIsLoading(false);
            toast.error(error.message, { theme: 'colored' })
        });
    }

    const capturedImage = (base64: string) => {
        base64 = base64.replace('data:image/png;base64,', '');
        const captureImageName = capturedImages.length === 0 ? `Image` : `Image-${capturedImages.length}`;
        const newFileObject = {
            contentType: 'image/png',
            ext: '.png',
            fileName: captureImageName,
            size: capturedImageSize(base64),
            id: GUID.NewGUID(),
            createdBy: loggedUser.userId,
            tenantId: loggedUser.tenantId,
            fileContent: base64
        } as FileUploadModel;
        setCapturedImages(oldArray => [...oldArray, newFileObject]);
    }

    const capturedImageSize = (base64: string): number => {
        var sizeInBytes = 4 * Math.ceil((base64.length / 3)) * 0.5624896334383812;
        return sizeInBytes
    }

    const captureImage = <CaptureImage
        onPictureTaken={capturedImage}
        onLoaded={() => {
            cameraRef.current?.start();
        }}
        ref={cameraRef}></CaptureImage>;

    const [fileUploadButtonValue, setFileUploadButtonValue] = useState<string>();
    return (
        <Loader isLoading={isLoading}>
            <div >
                {(props.hasDocumentUploaded && !props.isShowUpload) && (
                    <div className="form-group">
                        <div className='row'>
                        <div className='col-sm-12'>
                        <label
                            htmlFor="file-upload"
                            {...dragEvents}
                            className={clsx(FileUploadUtility.classes.root && FileUploadUtility.classes.onDragOver) + " file-upload-label"}
                        >
                            <Box
                                className={clsx(FileUploadUtility.classes.noMouseEvent) + " file-upload-innerbox"}
                            >
                                <Box
                                >
                                    {props.filestobeuploaded?.length! == 0 && <UploadOutlinedIcon className='upload-icon' />}
                                    <Typography><span className='file-text-transform'>{labelText}</span></Typography>
                                </Box>
                                {(props.filestobeuploaded?.length === 0 && !props.fileUploadConfiguration?.hideInnerUploadButton) &&
                                    <Button
                                        variant="outlined"
                                        component="label">
                                        Upload
                                        <input
                                            {...props}
                                            hidden
                                            accept={props.acceptextensions ?? '*'}
                                            id={props.controlkey}
                                            value={props.value}
                                            title={props.title}
                                            multiple={props.multiple ?? false}
                                            key={props.controlkey}
                                            {...(props.controlregister, ({
                                                onChange: (e: any) => {
                                                    setFile(e);
                                                }
                                            }) ?? {})}
                                            placeholder={props.placeholder}
                                            className="file-input" type="file"
                                        />
                                    </Button>}
                            </Box>
                        </label>
                        </div>
                        </div>
                    </div>
                )}
                {(props.hasDocumentUploaded && (props.isShowUpload)) && (
                    <div className="form-group row">
                        <div className='inline-flex'>
                            <Button
                                startIcon={props.buttonLabel ? <AddIcon /> : ""}
                                className={`${props.buttonLabel ? 'custom-button' : 'fileupload-button'}`}
                                variant="outlined"
                                component="label">
                                {props.buttonLabel ? 'Image' : 'Upload'}
                                <input
                                    {...props}
                                    hidden
                                    accept={props.acceptextensions ?? '*'}
                                    id={props.controlkey}
                                    value={props.value}
                                    title={props.title}
                                    multiple={props.multiple ?? false}
                                    key={props.controlkey}
                                    {...(props.controlregister, ({
                                        onChange: (e: any) => {
                                            setFile(e);
                                        }
                                    }) ?? {})}
                                    placeholder={props.placeholder}
                                    className="file-input" type="file"
                                />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {(props.isShowLoader && props.hasDocumentUploaded && !props.isShowUpload) &&
                    <>
                        <div className='document-upload rounded'>
                            <span> {dropLabelText}</span>
                        </div><br />
                        <div className='document-file-upload'>
                            {
                                (() => {
                                    let container: any = [];
                                    files.forEach((file, index) => {
                                        container.push(
                                            <div key={index}>
                                                <span>{file.name}</span>
                                            </div>)
                                    });
                                    return container;
                                })()
                            }
                        </div></>
                }
            </div>
            {/* differentControl */}
            <div className="form-group file-upload-container">
                {!props.hasDocumentUploaded && (
                    <div className="file-uploader-button-container">
                        {!props.isShowUploaderBox && <label>
                            <b> <span>{props.label}</span></b>
                            {isRequired &&
                                <i className={`required-icon`}></i>
                            }
                        </label>}
                        {!props.readOnly && props.isShowUploaderBox && <div className='inline-flex'>
                            <Button
                                className="uploader-box"
                                variant="outlined"
                                component="label">
                                <div className="text-center">
                                    <ImageIcon className="uploader-box-icon" ></ImageIcon>
                                    <div className='image-box-label'>{props.label}  {isRequired &&
                                        <i className={`required-icon`}></i>}</div>
                                </div>
                                <input
                                    {...props}
                                    hidden
                                    accept={props.acceptextensions ?? '*'}
                                    id={props.controlkey}
                                    value={fileUploadButtonValue}
                                    title={props.title}
                                    multiple={props.multiple ?? false}
                                    key={props.controlkey}
                                    {...(props.controlregister, ({
                                        onChange: (e: any) => {
                                            setFile(e);
                                        }
                                    }) ?? {})}
                                    placeholder={props.placeholder}
                                    className="file-input" type="file"
                                />
                            </Button></div>}

                        {!props.readOnly && !props.isShowUploaderBox && <div className='inline-flex upload-file-img-btn'>
                            <div className='multiupload-filesd'>
                            <Button
                                style={{ textTransform: 'none' }}
                                variant="outlined"
                                component="label">
                                <p><ImageIcon className="uploader-box-icon" ></ImageIcon></p>
                                <p className='d-block'><small>Add image</small></p>
                                <input
                                    {...props}
                                    hidden
                                    accept={props.acceptextensions ?? '*'}
                                    id={props.controlkey}
                                    value={props.value}
                                    title={props.title}
                                    multiple={props.multiple ?? false}
                                    key={props.controlkey}
                                    {...(props.controlregister, ({
                                        onChange: (e: any) => {
                                            setFile(e);
                                        }
                                    }) ?? {})}
                                    placeholder={props.placeholder}
                                    className="file-input" type="file"
                                />
                            </Button>
                            </div>
                            {props.allowImageCapture && <Button
                                variant="outlined"
                                style={{ textTransform: 'none' }}
                                className="image-capture"
                                startIcon={<CameraAltOutlinedIcon />} onClick={() => {
                                    setOpen(true);
                                }}> Capture </Button>}

                            <Modal isOpen={isOpen}
                                title={"Capture Image"}
                                onClose={() => {
                                    cameraRef.current?.stop();
                                    setCapturedImages([]);
                                    setOpen(false);
                                }}
                                additionalButtons={[{
                                    label: 'Add',
                                    disabled: capturedImages.length === 0,
                                    onClick: () => {
                                        cameraRef.current?.stop();
                                        if (props.mergeAllImages)
                                            setMyFiles([...myFiles, ...capturedImages]);
                                        setOpen(false);
                                    }
                                }]}>
                                <div className="col-sm-12">
                                    <div className="row">
                                        {captureImage}
                                    </div>
                                    <div className="row">
                                        {capturedImages.map(x => {
                                            return <div className="row">
                                                <div className="col-sm-4">
                                                    <span>{x?.fileName}</span>
                                                </div>

                                                <div className="col-sm-4">
                                                    <IconButton aria-label="edit" >
                                                        <PreviewIcon onClick={async () => {
                                                            previewOrDownloadBase64(x.fileContent, x.contentType, x.fileName, true);
                                                        }} />
                                                    </IconButton>
                                                    <IconButton aria-label="edit" >
                                                        <DownloadIcon onClick={async () => {
                                                            previewOrDownloadBase64(x.fileContent, x.contentType, x.fileName, false);
                                                        }} />
                                                    </IconButton>
                                                    < IconButton aria-label="remove" >
                                                        <DeleteIcon onClick={() => {
                                                            setCapturedImages((current) =>
                                                                current.filter((data) => data.id !== x.id)
                                                            );
                                                        }} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        })}
                                    </div>

                                </div>
                            </Modal>
                        </div>}
                        {
                            props.error ? (<span className="error">{props.error}</span>) : null
                        }
                    </div>
                )}
                {!props.hasDocumentUploaded && (
                    <div className='upload-data-box upload-imagemeter upload-imagemeter-multi'>
                        {myFiles.map(x => {
                            if (props.error) {
                                return <div></div>
                            }
                            if (x === undefined || x === null) {
                                return <div>{(props.error || props.hasDocumentUploaded) ? '' : `No file chosen`}</div>
                            }
                            console.log(x.fileContent, x.contentType, x.fileName, true)
                            return (<div className='multi-upload-ui'>
                               {<CloseIcon onClick={() => {
                                        props.ondelete?.(x.id!);
                                        setMyFiles(f =>
                                            f.filter((data) => data.id !== x.id)
                                        );
                                    }} ></CloseIcon>
                                }
                                <span title={x.fileName}>{x.fileName}</span>
                                <img src={`data:${x.contentType};base64,${x.fileContent}`} alt={x.fileName} />

                            </div>)
                        })}
                        
                        
                    </div>
                )}
            </div>
        </Loader>
    );
})

export default FileUpload;
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ProjectStatusEnum } from "constants/WorkItemStageEnum";
 

const ProjectStatusHelper = (props: GridRenderCellParams) => {
  const rowData = props.row;
  const ProjectStatusClass = (): string => {
    let activeClass: string = '';
    switch (rowData.statusLabel) {
        case ProjectStatusEnum.Active:
            activeClass = 'status-blue-tag';
            break;
        case ProjectStatusEnum.Pending:
            activeClass = 'status-grey-tag';
            break;
        case ProjectStatusEnum.Completed:
            activeClass = 'status-green-tag';
            break;
        case ProjectStatusEnum.Cancelled:
              activeClass = 'status-red-tag';
              break;
        default:
            activeClass = 'default-status-tag';
            break;
    }
    return activeClass;
}

  return (
      <div className={`status-tag-facility text-center fw-bold text-uppercase ${ProjectStatusClass()}`} > 
       <span className="">{props.value}</span>
    </div>
  );
};

export default ProjectStatusHelper;

import { IControlProps } from 'models/IControlProps';
import { IdLabelAvatarEmailLookup } from 'models/IdLabel';
import { useEffect, useRef, useState } from 'react';
import { AppFunctions } from 'helpers/AppFunctions';
import Loader from '../Loader';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Constants } from 'constants/Constants';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { debounce } from 'lodash';
import NameAvatar from '../NameAvatar';
import { useLocation } from 'react-router-dom';
import { BookedAppointmentNavigationModel } from 'pages/installations/work-assignment/BookedAppointmentRequestModel';
import { Tooltip } from "@mui/material";

export type DataServiceType = {
    dataUrl: string,
    getRecords: (dataUrl: string, payload: string, objectId: string) => Promise<IdLabelAvatarEmailLookup[]>
};

export type SearchableDropdownConfigurations = {
    isSearchBoxLarge: boolean,
    isSelectedItemBorderShow: boolean,
    isRequired: boolean,
    isSearchBoxVisible: boolean,
    isArrowIconShow: boolean,
    isDropdownArrowIconShow?: boolean,
    isRecentLabelShow: boolean,
    isSearchButtonShow: boolean,
    isShowCancel?: boolean,
    isDocumentShow?:boolean
}

type SearchableDropDownType = IControlProps &
{
    defaultOptions: IdLabelAvatarEmailLookup[],
    dataServiceType: DataServiceType,
    inputClassName?: string,
    searchFieldClassName?: string,
    form?: any,
    objectId?: string,
    multiple: boolean,
    wrapperCssClass?: string,
    configuration: SearchableDropdownConfigurations,
    hasAvatarName?: boolean,
    onChange?: (selectedItem: IdLabelAvatarEmailLookup) => void,
    hideSearchIcon?:boolean,
    noDataMessage?: string
}

export type SearchDropDownRefType = {
    setData: (options: IdLabelAvatarEmailLookup[]) => void;
}

const SearchableDropdown = React.forwardRef<SearchDropDownRefType, SearchableDropDownType>((props, ref): JSX.Element => {

    const [searchValue, setSearchValue] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState<IdLabelAvatarEmailLookup[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<IdLabelAvatarEmailLookup[]>([]);
    const [isListOpen, setIsListOpen] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false);
    const [isRecentShow, setIsRecentShow] = useState<boolean>(props.configuration.isRecentLabelShow ?? false);
    const [isShowCancel, setIsShowCancel] = useState<boolean>(props.readOnly ?? false);
    const location = useLocation();
    const navigationData: BookedAppointmentNavigationModel = location.state;
    const noDataMessage = props.noDataMessage ?? "Data not found"
    const loadServerRecords = async (payload: string): Promise<any> => {
        setLoading(true);
        return Promise.resolve(props.dataServiceType
            .getRecords(props.dataServiceType.dataUrl, payload, props?.objectId ?? navigationData?.projectId!))
            .then((data) => {
                setLoading(false);
                return data;
            }, () => {
                setLoading(false);
            })
    }

    const setSelectedValue = (options: IdLabelAvatarEmailLookup[]) => {
        setSelectedItems(options ? options : []);
        props.form?.setValue(props.controlkey, options, { shouldDirty: true });
    }

    React.useImperativeHandle(ref, () => ({
        setData: (options: IdLabelAvatarEmailLookup[]) => setSelectedValue(options)
    }));


    const debounceSearchTerm = debounce(async (searchTerm: string) => {
        if (props.configuration.isSearchButtonShow) return;
        if (AppFunctions.IsNullOrWhiteSpace(searchTerm)) {
            setFilteredOptions(props.defaultOptions ?? []);
            return;
        }
        loadServerRecords(searchTerm).then((data) => {
            setFilteredOptions(data);
        })
    }, 200);

    useEffect(() => {
        const selectedOptions = props.form?.getValues(props.controlkey);
        setSelectedItems(selectedOptions ?  selectedOptions : []);
        document.addEventListener('click', handleCloseList);
        return () => {
            document.removeEventListener('click', handleCloseList);
        }
    }, [props.defaultOptions]);


    const bindDropdownData = () => {
        if (AppFunctions.IsNullOrWhiteSpace(searchValue)) {
            setFilteredOptions(props.defaultOptions);
        }
        else {
            loadServerRecords(searchValue).then((data) => {
                setIsRecentShow(false);
                setFilteredOptions(data);
            })
        }
    }

    const handleCloseList = (e: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
            setIsListOpen(false);
            setIsRecentShow(true);
        }
    }

    const handleListItemSelectMultiple = (item: IdLabelAvatarEmailLookup) => {
        if (!selectedItems.some(selectedItem => selectedItem.id === item.id)) {
            setSelectedItems([...selectedItems!, item]);
            const updateSelectedItems = [...selectedItems!, item]
            props.form?.setValue
                (props.controlkey, updateSelectedItems,
                    { shouldDirty: true });
            setSearchValue('');
            props.onChange?.(item);
            setIsListOpen(false);
        }
    }

    const handleListItemSelectSingle = (item: IdLabelAvatarEmailLookup) => {
        setSelectedItems([item]);
        props.form?.setValue(props.controlkey, [item], { shouldDirty: true });
        props.form.trigger(props.controlkey);
        props.onChange?.(item);
        setSearchValue('');
        setIsListOpen(false);
    }

    const handleListItemSelect = props.multiple ?
        handleListItemSelectMultiple : handleListItemSelectSingle;

    const handleInputClick = () => {
        if (!props.readOnly) {
            setIsListOpen(!isListOpen);
            setFilteredOptions(props.defaultOptions);
        }
        else {
            setIsShowCancel(props.readOnly);
        }
    }

    const handleRemoveSelected = (item: IdLabelAvatarEmailLookup) => {
        const updateSelectedItems = selectedItems?.filter(
            selectedItem => selectedItem.id !== item.id
        );
        setSelectedItems(updateSelectedItems.length > 0 ? updateSelectedItems : []);
        props.form?.setValue(props.controlkey, updateSelectedItems, { shouldDirty: true });
        props.onChange?.(item);
        props.form.trigger(props.controlkey);
    }

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.configuration.isSearchButtonShow && event.key === Constants.EnterKeyName) {
            event.preventDefault();
            bindDropdownData();
        }
    }

    const handleInputSearch = (searchValue: string) => {
        setSearchValue(searchValue);
        setIsListOpen(true);
        debounceSearchTerm(searchValue);
    }
    const handleOpenList = () => {
        setIsListOpen(!isListOpen);
        debounceSearchTerm(searchValue);
    }
    const searchVisible = () => {
        if(!props.multiple && selectedItems.length == 1){
            return false
        }
        return true
    }

    const selectedItemWrapperClass: string = props.configuration.isSelectedItemBorderShow ? 'selected-items-wrapper selected-wrapper-border' : 'selected-items-wrapper';
    return (
        <Loader isLoading={loading}>
            <div ref={wrapperRef} className={`${props.wrapperCssClass} searchable-dropdown-wrapper`}>
                {
                    (!AppFunctions.IsNullOrUndefined(props.label) && props.label?.length) &&
                    <label htmlFor={props.controlkey} className="my-2 search-dropdown-label">
                        <span><b>{props.label}</b></span>
                        {props.isrequired && (
                            <i className={`required-icon`}></i>
                        )}
                    </label>
                }
               

                {props.configuration.isSearchBoxVisible
                    && (AppFunctions.IsNullOrUndefined(selectedItems) || props?.objectId) 
                    && searchVisible() &&
                    <div className='search-button-container'>
                        {props.hideSearchIcon ? "" : <SearchSharpIcon className="search-icon" />}

                        <input
                            type="search"
                            placeholder={props.placeholder}
                            className={props.searchFieldClassName ?? 'form-control'}
                            value={searchValue}
                            autoComplete="off"
                            readOnly={props?.readOnly ?? false}
                            spellCheck={false}
                            onFocus={(e) => {
                                setIsSearchFocus(true);
                                handleInputSearch(e.target.value)
                            }}
                            onBlur={() => setIsSearchFocus(false)}
                            onChange={(e) => {
                                handleInputSearch(e.target.value);
                            }}                            
                        ></input>
                        {props.configuration.isDropdownArrowIconShow && <ArrowDropDownIcon onClick={handleOpenList} />}
                    </div>}
                    {!AppFunctions.IsNullOrUndefined(selectedItems) && <div
                    className={selectedItemWrapperClass}
                    onClick={handleInputClick}
                >
                    {selectedItems?.map(item => (
                        <div
                            key={item.id}
                            className="selected-items">
                            {props.hasAvatarName &&
                                <div><NameAvatar hasAvatarName={true} name={item
                                    ?.avatarName ?? ""} /></div>}
                            <div className="option-label">
                                <div className='list-username'>
                                    {item.label.length > 40 ?
                                    <Tooltip title={item.label} arrow placement="top">
                                        <span>{item.label.slice(0,40)}...</span>
                                    </Tooltip>:<span>{item.label}</span>}
                                </div>
                                <div className="option-label-email">{item.email}</div>
                                {props.configuration.isDocumentShow && item.documents?.map(document => (
                                    <>
                                    <div className="info-label-type">{document.type}</div>
                                    <div className="list-username">{document.issuer} - {document.name}</div></>
                                ))}
                            </div>
                           
                            <div style={{ width: "30px" }}>
                                {(!isShowCancel) && (props.configuration.isSearchBoxVisible || props.multiple) && 

                                    <CloseIcon
                                        className="selected-list-cross-icon"
                                        onClick={() => handleRemoveSelected(item)} />

                                }
                            </div>
                            {props.configuration.isArrowIconShow && <ArrowDropDownIcon />}
                        </div>
                    ))}

                </div>}
                    {isListOpen
                    && <div className='search-list-container fade-up'>
                        {!props.configuration.isSearchBoxVisible
                            &&
                            <div className="search-input-container">
                                <div className={`search-box ${isSearchFocus ? "focused" : ""}`}>
                                    <SearchSharpIcon className="search-icon" />
                                    
                                    <input
                                        autoFocus
                                        type="search"
                                        placeholder={props.placeholder}
                                        readOnly={props?.readOnly ?? false}
                                        className={props.searchFieldClassName ?? 'form-control'}
                                        value={searchValue}
                                        autoComplete="off"
                                        spellCheck={false}
                                        onFocus={() => setIsSearchFocus(true)}
                                        onBlur={() => setIsSearchFocus(false)}
                                        onKeyDown={onKeyDownHandler}
                                        onChange={(e) => {
                                            handleInputSearch(e.target.value);
                                        }}
                                    ></input>
                                </div>
                                {props.configuration.isSearchButtonShow &&
                                    <Button
                                        className={"custom-button"}
                                        size="medium"
                                        variant={"text"}
                                        onClick={bindDropdownData}>
                                        Search</Button>}
                            </div>}
                        <div className='list-options-container'>
                            {filteredOptions?.length > 0
                                && props.configuration.isRecentLabelShow
                                && isRecentShow
                                && <div
                                    className="rct-lbl-color">
                                    RECENT</div>}
                            <div className='search-list-option-wrapper'>
                                {filteredOptions?.map((option) => (
                                    <>{props.configuration.isDocumentShow && !option.isDocumentAvailable ? (<div
                                        className="search-list-group-item disabled-list-search"
                                        key={option.id}
                                    >
                                        {props.hasAvatarName &&
                                            <div className="ps-2 mb-0">
                                                <NameAvatar hasAvatarName={true} name={option.avatarName ?? ""} />
                                            </div>}
                                        <div className="option-label mt-2">
                                            <div className='list-username'> {option.label}</div>
                                            <div className="option-label-email">{option.email}</div>
                                            <div className='option-label-info'>{option.message}</div>
                                        </div>
                                    </div>):(<div
                                        className="search-list-group-item"
                                        key={option.id}
                                        onClick={() => handleListItemSelect(option)}
                                    >
                                        {props.hasAvatarName &&
                                            <div className="ps-2 mb-0">
                                                <NameAvatar hasAvatarName={true} name={option.avatarName ?? ""} />
                                            </div>}
                                        <div className="option-label mt-2">
                                            <div className='list-username'> {option.label}</div>
                                            <div className="option-label-email">{option.email}</div>
                                            {(option?.message?.length ?? 0) > 0 && <div className='option-label-info'>{option.message}</div>}
                                        </div>
                                    </div>)}</>
                                   
                                ))}
                                {filteredOptions?.length <= 0
                                    && !isRecentShow
                                    && <div className="search-list-group-item">{noDataMessage}</div>}
                            </div>
                        </div>
                    </div>}
                    

               
                {
                    props.error ? (<div className="error">{props.error}</div>) : null
                }
            </div>

        </Loader>
    );
});
export default SearchableDropdown;
import { ProtectedRoute, Suspense, lazy, Loader } from "pages/RouteIndex";
import { scheduleAppointmentRoutes } from "./appointment/appointmentRoutes";
const LazyWaterMeterTransmitterDetailsComponent = lazy(() => import('./water-meters/WaterMeterTransmitterDetails'));
const LazyDeviceDetailsComponent = lazy(() => import('./devices/device-details/DeviceDetails'));
const LazyEditFacilityComponent = lazy(() => import('./EditFacility'));
const LazyFacilityManagementComponent = lazy(() => import('./FacilityManagement'));

export const facilityRoutes = [
    {
        path: "facility-management",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyFacilityManagementComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "facility/edit/:clientId/:facilityId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyEditFacilityComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "facility/edit/:clientId/:facilityId/device/:deviceId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyDeviceDetailsComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "project/:projectId/:clientId/facility/edit/:clientId/:facilityId/device/:deviceId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyDeviceDetailsComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "facility/edit/:clientId/:facilityId/watermetertransmitter/:waterMeterTransmitterId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyWaterMeterTransmitterDetailsComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    {
        path: "project/:projectId/:clientId/facility/edit/:clientId/:facilityId/watermetertransmitter/:waterMeterTransmitterId",
        element: (
            <ProtectedRoute>
                <Suspense fallback={<Loader children={""} isLoading={true} />}>
                    <LazyWaterMeterTransmitterDetailsComponent />
                </Suspense>
            </ProtectedRoute>
        ),
    },
    ...scheduleAppointmentRoutes
]
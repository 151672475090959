import { Fragment, useMemo } from "react"
import { Constants } from "../constants/Constants"
import { Calendar, Event, EventProps, momentLocalizer, NavigateAction, View, SlotInfo, DateLocalizer } from 'react-big-calendar'
import moment from 'moment'
import CustomCalendarToolbar from "common/utils/CustomCalendarToolbar"
import { CalendarProps } from "../models/CalendarProps"
import 'react-big-calendar/lib/css/react-big-calendar.css';

const BigCalendar = (props: CalendarProps) => {
    const localizer = momentLocalizer(moment)

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
            defaultDate: props.defaultDate,
            scrollToTime: moment().toDate(),
            myEvents: props.events!
        }),
        []
    )

    const formats = {
        weekdayFormat: (date: Date, culture?: string, localizer?: DateLocalizer) => localizer?.format(date, 'dddd', culture)!,
    }

    const onNavigate = (date: Date, view: View, action: NavigateAction) => {
        props.onNavigate?.(date, view, action);
    }

    const onView = (view: View) => {
        props.onView?.(view);
    }

    const onSelectEvent = (event: Event) => {
        let selectedEvent = event as EventProps;
        props.onSelectEvent?.(selectedEvent)
    }

    const onSelectSlot = (slotinfo: SlotInfo) => {
        props.onSelectSlot?.(slotinfo);
    }


    return (
        <Fragment>
            <div className={props.type !== Constants.ControlTypeHidden ? `form-group` : ``}>
                {props.type !== Constants.ControlTypeHidden && <label htmlFor={props.controlkey}>
                    <span>{props.label}</span>
                </label>}
                <div className="height600" style={{ minHeight: 500 }} {...props}>
                    <Calendar
                        className="big-calender-parent"
                        components={{
                            toolbar: CustomCalendarToolbar,

                            month: {
                                event: (item) => {
                                    return !props?.isScheduling
                                        ?
                                        (
                                            <>
                                                {item.event?.isActive ?
                                                    (<></>) :
                                                    (<div style={{ fontSize: '0.875rem', lineHeight: '1rem', fontFamily: 'Proxima Nova' }}>
                                                        {item.event.assigned} <span>Assigned</span>
                                                        {(item.event.unAssigned! > 0) && (<><br />{item.event.unAssigned} <span style={{ marginLeft: '2px' }}>Unassigned</span></>)}
                                                    </div>
                                                    )}
                                            </>
                                        ) :
                                        (
                                            <div style={{ fontSize: '0.688rem', lineHeight: '1.125rem', paddingTop: '0.313rem' }}>{item.event.title}
                                                <span style={{ float: 'right', fontWeight: 'bold', color: '#30BCB1' }}>{item.event.isActive ? 'ACTIVE' : ''}</span>
                                                <br />{item.event.slots} <span>Slots</span> | {item.event.appointments} <span>Appointments</span></div>
                                        )
                                }
                            },
                            week: {
                                event: (item) =>
                                    <div style={{ fontSize: '12px', lineHeight: '15px' }}>
                                        <span className="weekly-view-block-title"> {!item.event.timeWindow?.noOfSlots ? item.event.title : ''}</span>
                                        <span className="weekly-blockview-active-tag fw-bold">{(item.event.isActive && item.event.start?.getHours() === 0) ? 'ACTIVE' : ''}</span>
                                        {item.event.timeWindow?.noOfSlots && props.isScheduling &&
                                            <>
                                                {item.event.timeWindow?.bookedSlot}/{item.event.timeWindow?.noOfSlots}
                                            </>}

                                        {item.event.timeWindow?.bookedSlot! > 0 && item.event.timeWindow?.bookedSlot && !props.isScheduling &&
                                            <>
                                                {(item.event.timeWindow?.assigned! > 0) && (<><br />{item.event.timeWindow?.assigned}<span> Assigned</span></>)}
                                                {(item.event.timeWindow?.unAssigned! > 0) && (<><br />{item.event.timeWindow?.unAssigned}<span> Unassigned</span></>)}
                                                <br />{item.event.timeWindow?.bookedSlot}<span> appointments scheduled</span>
                                            </>}
                                    </div>
                            }
                        }}
                        defaultDate={props.defaultDate ?? defaultDate}
                        dayPropGetter={props.dayPropGetter!}
                        slotPropGetter={props.slotPropGetter!}
                        date={props.defaultDate}
                        formats={formats}
                        defaultView={props.defaultView}
                        localizer={localizer}
                        events={props.events!}
                        max={props.max!}
                        step={props.step!}
                        selectable={props.selectable}
                        showMultiDayTimes
                        style={{ height: 800, width: '100%' }}
                        onNavigate={(date, view, action) => onNavigate(date, view, action)}
                        onView={(view) => onView(view)}
                        views={{ week: true, month: true }}
                        eventPropGetter={props.eventStyleGetter!}
                        onSelectEvent={(event) => onSelectEvent(event)}
                        onSelectSlot={(slotInfo) => onSelectSlot(slotInfo)}
                        scrollToTime={scrollToTime}
                        timeslots={2}
                    />
                </div>
            </div>
        </Fragment>)
}
export default BigCalendar;
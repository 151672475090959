import { Add as AddIcon, Delete as DeleteIcon, Download as DownloadIcon, Edit as EditIcon, Email as EmailIcon, Message as MessageIcon, MoreHoriz, Print as PrintIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import FileSearchIcon from 'common/icons/FileSearchIcon';
import storage from 'common/utils/storage';
import { NameAvatar, NameAvatarWithCircle } from 'components/Index';
import { NormalizedRoleEnum } from 'constants/NormalizedRoleEnum';
import { AppFunctions } from 'helpers/AppFunctions';
import { SingleSelectCustomComponent } from 'components/controls/DataGrid/Components/Index';
import { GenericOperators } from 'components/controls/DataGrid/Operators/GenericOperators';
import { RenderExpandableCell } from 'components/controls/DataGrid/Renderer/Index';
import { ColumnDefinition, DataGridActionsCellItem, IDataGridProps } from "components/controls/DataGrid/models/Index";
import { NavLink } from 'react-router-dom';
import { SaveURLState } from 'helpers/RouterStateHelper';
import { ColumnDefinitionPreferenceType } from './DataGrid';
import { cloneDeep } from 'lodash';

export const DataGridColumns = (props: IDataGridProps, loggedInUserRole: string, orderdColumns: string[], coldef?: GridColDef) => {    
    let columns = (cloneDeep(props.columns) ?? cloneDeep(props.dataGridServiceType?.columns!));
    let hasWritePermission = props.permission?.permission === 'RW'
    if (AppFunctions.IsNullOrUndefined(props.permission) && (loggedInUserRole === NormalizedRoleEnum.SuperAdmin || loggedInUserRole === NormalizedRoleEnum.AdministrativeManager)) {
        hasWritePermission = loggedInUserRole === NormalizedRoleEnum.SuperAdmin;
    }
    let parsedColumnDefinitionPreference: ColumnDefinitionPreferenceType[] = [];
    let orderedFields: string[] = orderdColumns;
    if (props.moduleTitle) {
        const localStorageUserPreference = storage.getUserPreference();
        if (AppFunctions.IsNullOrWhiteSpace(localStorageUserPreference)) return;
        let userPreferences = JSON.parse(localStorageUserPreference as string);
        let columnPreferences = userPreferences.userPreference.find((x: any) => x.pageName == props.moduleTitle)?.gridColumnPreference;
        if (columnPreferences) {
            columns.filter(x => columnPreferences.split(",").includes(x.field)).map(x => x.hideColumnVisibility == false);
            columns.filter(x => !columnPreferences.split(",").includes(x.field)).map(x => x.hideColumnVisibility == true);
        }
        const columnsPreference = userPreferences.userPreference.find((x: any) => x.pageName == props.moduleTitle);
        parsedColumnDefinitionPreference = columnsPreference?.columnDefinitionPreference ? JSON.parse(columnsPreference?.columnDefinitionPreference) : [];
        if (AppFunctions.IsNullOrUndefined(orderdColumns)) {
            orderedFields = columnsPreference?.gridColumnOrderPreference ? JSON.parse(columnsPreference?.gridColumnOrderPreference) : [];
        }
    }
    const hasDeletePermission =
        props.canDeleteDataRow && hasWritePermission
        && (loggedInUserRole === NormalizedRoleEnum.SuperAdmin || loggedInUserRole === NormalizedRoleEnum.AdministrativeManager);

    let gridColsDef: GridColDef<ColumnDefinition>[] = [];

    columns.forEach(column => {
        if (column.type !== 'actions') {
            const col = column as GridColDef<ColumnDefinition>;
            if (coldef && col.field === coldef.field) {
                col.width = coldef.width;
            }
            if (parsedColumnDefinitionPreference && parsedColumnDefinitionPreference.length > 0) {
                const existingColDefPreference = parsedColumnDefinitionPreference.find(x => x.field == col.field);
                if (existingColDefPreference) {
                    if (coldef && col.field === coldef.field) {
                        col.width = coldef.width;
                    }
                    else
                    col.width = existingColDefPreference.width;
                }
            }
            if (col.type === 'date')
                col.valueFormatter = (params: GridValueFormatterParams) => AppFunctions.ConvertToDate(params.value);
            if (col.type === 'monthYear')
                col.valueFormatter = (params: GridValueFormatterParams) => AppFunctions.ConvertToMomentMothYear(params.value);
            if (col.type === 'dateTime')
                col.valueFormatter = (params: GridValueFormatterParams) => AppFunctions.ConvertToDateTimeMeridian(params.value);
            if (!props.rowHeightAuto && column.type === 'string' && column.renderCell == null) {
                col.renderCell = (params: GridRenderCellParams) => (
                    <RenderExpandableCell {...params} />
                );
            }
            if (col.type === 'avatarEllipse') {
                col.renderCell = (params: any) => {
                    if (params.row[column.avatarFieldKey || params.field] == null)
                        return '';

                    return (
                        <div className="user-name-wrapper">
                            <NameAvatarWithCircle name={params.formattedValue} />
                            <span>{params.formattedValue}</span>
                        </div>
                    )
                };
            }
            if (col.type === 'amount') {
                col.type = 'number'
                col.valueFormatter = (params: GridValueFormatterParams) =>
                    !AppFunctions.IsNullOrWhiteSpace(params.value) ? AppFunctions.ConvertToCurrency(params.value) : null;
            }
            if (col.type === 'avatar') {
                col.renderCell = (params: any) => {
                    if (params.row[column.avatarFieldKey || params.field] == null)
                        return '';

                    return (
                        <div className="user-name-wrapper">
                            {!AppFunctions.IsNullOrWhiteSpace(params.formattedValue) && <NameAvatar name={params.formattedValue} />}
                            <span>{params.formattedValue}</span>
                        </div>
                    )
                };
            }
            if (col.type === 'singleSelect')
                col.renderCell = SingleSelectCustomComponent;

            if (col.hideable === false && col.cellClassName != 'cursor-pointer')
                col.filterable = false;

            if (col.cellClassName === 'cursor-pointer') {
                col.disableReorder = true;
                if (column.cellNavigation)
                    col.renderCell
                        = (params: GridRenderCellParams) => {
                            const navigationFunction = column.cellNavigation!(params);
                            return <NavLink to={navigationFunction.to} state={navigationFunction?.state} className='gridcellclick-column' onClick={() => {
                                if (navigationFunction.to)
                                    SaveURLState(navigationFunction.to, navigationFunction?.state)
                            }}> {params.value}</NavLink>
                            
                        };
            }

            col.filterOperators = GenericOperators(col);
            gridColsDef.push(col);
        }
        else {
            const gridCol = {
                field: column.field,
                headerName: column.headerName,
                type: column.type!,
                width: column.width,
                flex: column.flex,
                cellClassName: column.cellClassName,
                getActions: (params) => {
                    let actions: any[] = [];
                    const colActions = column.getActions! as DataGridActionsCellItem[];
                    colActions.forEach(act => {
                        if (act.getterColumnParam)
                            act = act.getterColumnParam?.(params);
                        switch (act.actionType) {
                            case 'edit':
                                const editViewIcon = act.hideTooltip ?
                                    (hasWritePermission ? <EditIcon /> : <VisibilityIcon />)
                                    : (<Tooltip title={hasWritePermission ? act.tooltipIconMessage ?? 'Edit' : act.tooltipIconReadOnlymessage ?? 'View'
                                    } placement="top-start">
                                        {hasWritePermission ? <EditIcon /> : <VisibilityIcon />}</Tooltip>)
                                actions.push(<GridActionsCellItem icon={editViewIcon} label={act.label} className={act.className} onClick={act.onClick(params)} />)
                                break;
                            case 'delete':
                                const deleteIcon = act.hideTooltip ?
                                    <DeleteIcon />
                                    : (< Tooltip title='Delete' placement="top-start" >
                                        <DeleteIcon />
                                    </Tooltip>);
                                if (hasDeletePermission)
                                    actions.push(<GridActionsCellItem icon={deleteIcon} label={act.label} className={act.className} onClick={act.onClick(params)} />)
                                break;
                            case 'view':
                                const viewIcon = act.hideTooltip ?
                                    (<VisibilityIcon />)
                                    : (<Tooltip title={act.tooltipIconMessage ?? 'View'} placement="top-start">
                                        {<VisibilityIcon />}</Tooltip>)
                                actions.push(<GridActionsCellItem icon={viewIcon} label={act.label} className={act.className} onClick={act.onClick(params)} />)
                                break;

                            case 'add':
                                const addIcon = act.hideTooltip ?
                                    (hasWritePermission ? <AddIcon /> : <VisibilityIcon />)
                                    : (<Tooltip title={hasWritePermission ? act.tooltipIconMessage ?? 'Add' : act.tooltipIconReadOnlymessage ?? 'View'
                                    } placement="top-start">
                                        {hasWritePermission ? <AddIcon /> : <VisibilityIcon />}</Tooltip>)

                                actions.push(<GridActionsCellItem icon={addIcon} label={act.label} className={act.className} onClick={act.onClick(params)} />)
                                break;

                            case 'message':
                                const messageIcon = act.hideTooltip ?
                                    <MessageIcon />
                                    : <Tooltip title={act.tooltipIconMessage ?? 'Message'} placement="top-start">
                                        {<MessageIcon />}</Tooltip>

                                actions.push(<GridActionsCellItem icon={messageIcon} label={act.label} className={act.className} onClick={act.onClick(params)} />)
                                break;

                            case 'download':
                                const downloadIcon = act.hideTooltip ?
                                    <DownloadIcon />
                                    : <Tooltip title={act.tooltipIconMessage ?? 'Download'} placement="top-start">
                                        {<DownloadIcon />}
                                    </Tooltip>

                                actions.push(<GridActionsCellItem icon={downloadIcon} label={act.label} className={act.className} onClick={() => act.onClick(params)} />)
                                break;
                            case 'preview':
                                const previewIcon = act.hideTooltip ?
                                    <FileSearchIcon className='datagrid-icon' />
                                    : <Tooltip title={act.tooltipIconMessage ?? 'Preview'} placement="top-start">
                                        {<FileSearchIcon className='datagrid-icon' />}</Tooltip>

                                actions.push(<GridActionsCellItem icon={previewIcon} label={act.label} className={act.className} onClick={() => act.onClick(params)} />)
                                break;

                            case 'email':
                                const emailIcon = act.hideTooltip ?
                                    <EmailIcon />
                                    : <Tooltip title={act.tooltipIconMessage ?? 'Email'} placement="top-start">
                                        {<EmailIcon />}</Tooltip>

                                actions.push(<GridActionsCellItem icon={emailIcon} label={act.label} className={act.className} onClick={act.onClick(params)} />)
                                break;

                            case 'print':
                                const printIcon = act.hideTooltip ?
                                    (hasWritePermission ? <PrintIcon /> : <VisibilityIcon />)
                                    : (<Tooltip title={hasWritePermission ? act.tooltipIconMessage ?? 'Add' : act.tooltipIconReadOnlymessage ?? 'View'
                                    } placement="top-start">
                                        {hasWritePermission ? <PrintIcon /> : <VisibilityIcon />}</Tooltip>)

                                actions.push(<GridActionsCellItem icon={printIcon} label={act.label} className={act.className} onClick={(e) => act.onClick(params, e)} />)
                                break;

                            case 'more':
                                if (act.renderColumnAction?.(params)) {
                                    actions.push(<GridActionsCellItem showInMenu={false} icon={<MoreHoriz />} label={act.label} className={act.className} />)
                                }
                                else {
                                    actions.push(<GridActionsCellItem showInMenu label={act.label} className={act.className} disabled={act.disabled} onClick={act.onClick(params)} />)
                                }
                                break;
                        }
                    })
                    return actions;
                }
            } as GridColDef<ColumnDefinition>;
            gridColsDef.push(gridCol);
        }
    })

    if (orderedFields.length === 0)
        return gridColsDef;

    return orderedFields.reduce((acc: any, field: any) => {
        return [...acc, gridColsDef.find(x => x.field == field)];
    }, []);
}

export default DataGridColumns;
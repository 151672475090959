import { ColumnDefinition, DataGridActionsCellItem, DataGridServiceType, IDataGridRequestPayload, IDataGridResponse } from "components/controls/DataGrid/models/Index";
import { SetClientBaseMicroserviceURL, SetUserAndTenantBaseMicroserviceURL } from "config";
import { IHCResponceModel, LookupDropdownModel } from "models/Index";
import { ProjectClientViewModel } from "models/client/project/ProjectClientViewModel";
import { UserRoleModel } from "models/client/project/UserRoleModel";
import { AddProjectModel } from "models/project/AddProject";
import { CopyProjectModel } from "models/project/CopyProject";
import { SizeBasedPricingSchemaType } from "pages/client/projects/BillingSetup/SizeBasedPricing/SizeBasedPricingSchema";
import { ProjectDetailModel } from "pages/client/projects/Project";
import { GeneralPricingSchemaType, NoticeFeeSchemaType } from "pages/client/projects/ProjectBillingSetup";
import { ProjectInformationModel } from "pages/client/projects/ProjectInformation";
import { AdditionalServiceSchemaType, ProjectAdditionalServiceSchemaType } from "pages/common/billing-setup/AdditionalServicePricingSchema";
import { axios } from "common/utils/axios";
import ProjectStatusHelper from "pages/client/projects/ProjectStatusHelper";
import { AdditionalColumnDefinition, NavigateFunction } from "components/controls/DataGrid/models/ColumnDefinition";
import { ProjectNavigationModel } from "models/project/ProjectNavigationModel";



const copyProjectAsync = async (model: CopyProjectModel): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/copyproject`, model);
}

const getClientAliasOptionsAsync = async (): Promise<ProjectClientViewModel[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/project/getclientaliasoptions`);
}

const getUsersByNormalizedRoleNameListAsync = async (normalizedRoleNameList: string[]): Promise<UserRoleModel[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.post(`api/role/getusersbynormalizedrolenamelist`, normalizedRoleNameList);
}

const getAllProjectsAsync = (): string => `api/project/getallprojects`;
const getRows = async (dataUrl: string, payload: IDataGridRequestPayload): Promise<IDataGridResponse> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(dataUrl, payload);
}

const getProjectStatusAsync = async (name: string): Promise<LookupDropdownModel[]> => {
    SetUserAndTenantBaseMicroserviceURL();
    return await axios.get(`api/lookup/getlookupbyname/${name}`);
}

const getSectorsByProjectIdAsync = async (projectId: string): Promise<string[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/project/getsectorsbyprojectid/${projectId}`);
}


const saveProjectNoticeFeelPricingAsync = async (model: NoticeFeeSchemaType): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/saveprojectnoticefeelpricing`, model);
}


const saveProjectGeneralPricingAsync = async (model: GeneralPricingSchemaType): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/saveprojectgeneralpricing`, model);
}

const getSizeBasedPricingByProjectIdAsync = async (projectId: string): Promise<SizeBasedPricingSchemaType[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/project/getsizebasedpricingbyprojectid/${projectId}`);
}

const saveSizeBasedPricingAsync = async (model: SizeBasedPricingSchemaType): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/savesizebasedpricing`, model);
}

const saveAdditionalServicePricingAsync = async (model: AdditionalServiceSchemaType): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/saveadditionalservicepricing`, model);
}

const getProjectAdditionalServicePricingByProjectIdAsync = async (projectId: string): Promise<ProjectAdditionalServiceSchemaType[]> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/project/getprojectadditionalservicepricingbyprojectid/${projectId}`);
}

const saveProjectAdditionalServicePricingAsync = async (model: ProjectAdditionalServiceSchemaType): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/saveprojectadditionalservicepricing`, model);
}

const addNewProjectAsync = async (model: AddProjectModel): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/addnewproject`, model);
}

const getProjectDetailByIdAsync = async (projectId: string): Promise<ProjectDetailModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.get(`api/project/getprojectdetailbyid/${projectId}`);
}

const updateProjectInformationAsync = async (model: ProjectInformationModel): Promise<IHCResponceModel> => {
    SetClientBaseMicroserviceURL();
    return await axios.post(`api/project/updateprojectInformation`, model);
}

export const ProjectService = {
    copyProjectAsync: copyProjectAsync,
    getClientAliasOptionsAsync: getClientAliasOptionsAsync,
    getUsersByNormalizedRoleNameListAsync: getUsersByNormalizedRoleNameListAsync,
    getProjectStatusAsync: getProjectStatusAsync,
    getSectorsByProjectId: getSectorsByProjectIdAsync,
    addNewProjectAsync: addNewProjectAsync,
    getProjectDetailByIdAsync: getProjectDetailByIdAsync,
    updateProjectInformationAsync: updateProjectInformationAsync,
    saveProjectGeneralPricingAsync: saveProjectGeneralPricingAsync,
    saveProjectNoticeFeelPricingAsync: saveProjectNoticeFeelPricingAsync,
    saveSizeBasedPricingAsync: saveSizeBasedPricingAsync,
    getSizeBasedPricingByProjectIdAsync: getSizeBasedPricingByProjectIdAsync,
    saveAdditionalServicePricingAsync: saveAdditionalServicePricingAsync,
    getProjectAdditionalServicePricingByProjectIdAsync: getProjectAdditionalServicePricingByProjectIdAsync,
    saveProjectAdditionalServicePricingAsync: saveProjectAdditionalServicePricingAsync
}

export const onCellNavigation = (data: any, additional : AdditionalColumnDefinition): NavigateFunction => {
    const projectNavigationModel = {
        projectId: data.id as string,
        clientId: data.row.clientId as string
    } as ProjectNavigationModel;
    let basePath = `/project/${projectNavigationModel.projectId}/${projectNavigationModel.clientId}/details`;
    return { to: basePath};
}

const columns = (actions: DataGridActionsCellItem[], additional : AdditionalColumnDefinition): ColumnDefinition[] => {
    return [
        { field: 'id', headerName: 'ID', type: 'number', hideable: false },
        { field: 'projectName', headerName: 'Project Name', width: 600, type: 'string', cellClassName: 'cursor-pointer', hideable: false,
            cellNavigation:(row: any)=> {return onCellNavigation (row, additional)}
         },
        { field: 'clientAlias', headerName: 'Client Alias', width: 250, type: 'string', },
        { field: 'projectNumber', headerName: 'Project Number', width: 85, type: 'string' },
        { field: 'projectTypeLabel', headerName: 'Project Type', width: 250, type: 'string' },
        { field: 'projectSubTypeLabel', headerName: 'Project Sub Type', width: 200, type: 'string' },
        { field: 'statusLabel', headerName: 'Status', width: 100, type: 'string', renderCell: ProjectStatusHelper },
        { field: 'startDate', headerName: 'Start Date',  width: 120, type: 'date' },
        { field: 'endDate', headerName: 'End Date',  width: 120, type: 'date' },
        { field: 'stateCode', headerName: 'State', width: 60, type: 'string' },        
        { field: 'totalMeterInstallations', headerName: 'Total Meter Installations', width: 110, type: 'number', hideColumnVisibility: true },
        { field: 'meterInstallationsCompleted', headerName: 'Meter Installations Completed', width: 110, type: 'number', hideColumnVisibility: true },
        { field: 'totalTransmeterInstallations', headerName: 'Total Transmeter Installations', width: 110, type: 'number', hideColumnVisibility: true },
        { field: 'transmeterInstallationsCompleted', headerName: 'Transmeter Installation Completed', width: 110, type: 'number', hideColumnVisibility: true },
        { field: 'ordinance', headerName: 'Ordinance', type: 'string', width: 120, hideColumnVisibility: true },
        { field: 'projectTypeId', headerName: 'Project Type Id', type: 'string', width: 150, hideable: false },
        { field: 'projectSubTypeId', headerName: 'Project Sub Type Id', type: 'string', width: 100, hideable: false },
        { field: 'statusId', headerName: 'Status Id', type: 'string', width: 140, hideable: false },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 80,
            getActions: actions
        }
    ]
};

export const projectManagementServiceDataGrid = (actions: DataGridActionsCellItem[], additional : AdditionalColumnDefinition): DataGridServiceType => {

    return {
        columns: columns(actions, additional),
        dataUrl: getAllProjectsAsync(),
        getRows: getRows
    };
}


import { Fragment, useEffect, useState } from "react";
import { ResetPasswordForm, ResetPasswordSchemaType } from "./ResetPasswordForm";
import { ResetPasswordServices } from "common/services/forgot-password/ResetPasswordServices";
import { ResetPasswordResponse, IResetPasswordResponse } from "models/forgot-password/IResetPasswordResponse";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "components/Loader";
import { HttpResponseType } from "models/IHCResponceModel";

export const ResetPassword = () => {
    const [resetPasswordResponse, setResetPasswordResponse] = useState<IResetPasswordResponse>(new ResetPasswordResponse());
    const [isLoading, setIsLoading] = useState(false);
    const { action } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    let isSetPassword = false;
    const email = searchParams.get("email");
    const token = searchParams.get("token");
    const isCompanyUser = searchParams.get("companyuser");
    console.log(isCompanyUser, ' isCompanyUser ');
    const [isValidToken, setIsValidToken] = useState<boolean>(false);

    if (action === "setpassword") {
        isSetPassword = true;
    }

    useEffect(() => {
        setIsLoading(true);
        ResetPasswordServices
            .verifyResetPasswordTokenAsync(email!, token!)
            .then(response => {
                setIsLoading(false);
                if (response.status == HttpResponseType.Failed.toString()) {
                    setIsValidToken(true);
                }
            });
    }, [email, token]);

    const handleSubmit = async (data: ResetPasswordSchemaType) => {
        setIsLoading(true);
        await ResetPasswordServices
            .resetPasswordAsync(data.newpassword, email!, token!, action!)
            .then(response => {
                setIsLoading(false);
                if (isCompanyUser && isCompanyUser.toUpperCase() === 'TRUE') {
                    navigate('/set-password/user-reset-password-successfully');
                    return;
                }
                if (isSetPassword) {
                    navigate('/login');
                }
                else {
                    setResetPasswordResponse(response);
                }

            });
    }

    return (
        <Fragment>
            <Loader isLoading={isLoading}>
                <div className="forgot-password container my-3 mt-5">
                    {isValidToken && (
                        <div className="col-sm-12">
                            <h2 className="password-header fw-normal mb-2">The link has expired.</h2>
                            <span><p>
                                Please initiate a new password reset request by clicking on the <Link to="/forgot-password">Forgot Password</Link> link.
                            </p></span>
                        </div>
                    )}
                    {!isValidToken && (
                        <>

                            {resetPasswordResponse.status !== '' && (
                                <div className={resetPasswordResponse.status == HttpResponseType.Success.toString() ? "col-sm-12 space-password" : "col-sm-12 error"}>
                                    <span>{resetPasswordResponse.message}</span>
                                    {resetPasswordResponse.status == HttpResponseType.Success.toString() && (
                                        <span> Click here to <Link to="/login">Login</Link> </span>
                                    )}
                                </div>
                            )}
                            {resetPasswordResponse.status != HttpResponseType.Success.toString() && (
                                <>
                                    <ResetPasswordForm
                                        onSubmitReady={handleSubmit}
                                        newPasswordLabel={isSetPassword ? "Password" : "New Password"}
                                        confirmPasswordLabel={isSetPassword ? "Confirm Password" : "Confirm New Password"}
                                        buttonLabel={isSetPassword ? "Set Password" : "Reset Password"}
                                    />
                                </>)}
                        </>)}
                </div>
            </Loader>
        </Fragment>
    )
}

export default ResetPassword;
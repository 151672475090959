import { IdLabelLookup } from "models/IdLabel";

export enum ProjectTypeEnum{
    MeterTransmitterExchange = "METER_TRANSMITTER_EXCHANGE",
    MeterExchange = "METER_EXCHANGE",
    TransmitterExchange = "TRANSMITTER_EXCHANGE",
    BPATesting = "BPA_TESTING",
}

export enum ProjectTypeCategoryEnum{
    MeterExchange = "METER_EXCHANGE_INSTALLATION",
    Testing = "TESTING",
    CCC = "CCC"
}

export const ProjectTypeOptions: IdLabelLookup[] = [
    { id: ProjectTypeEnum.MeterTransmitterExchange, label: "Meter & Transmitter Exchange" },
    { id: ProjectTypeEnum.MeterExchange, label: "Meter Exchange" },
    { id: ProjectTypeEnum.TransmitterExchange, label: "Transmitter Exchange" },
    { id: ProjectTypeEnum.BPATesting, label: "BPA Testing" }
]

export const ProjectTypeCategoryOptions: IdLabelLookup[] = [
    { id: ProjectTypeCategoryEnum.MeterExchange, label: "Meter Exchange/Installations" },
    { id: ProjectTypeCategoryEnum.Testing, label: "Testing" },
    { id: ProjectTypeCategoryEnum.CCC, label: "CCC" }
]

enum ProjectSubTypeEnum {
    Residential = "1380b626-4c37-4566-8762-8ee931d14b49",
    Commercial = "f85109b3-4d7c-48f8-b5bf-ce0242051f08"
}

export const ProjectSubTypeOptions: IdLabelLookup[] = [
    { id: ProjectSubTypeEnum.Residential, label: "Residential" },
    { id: ProjectSubTypeEnum.Commercial, label: "Commercial" }
]
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import storage from "./storage";
import { AppFunctions } from "helpers/AppFunctions";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { IUserSession } from "models/login/AuthenticationResponse";
import { refreshToken } from "./AuthKitStore";
import { toast } from "react-toastify";

export const ProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { pathname } = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const signOut = useSignOut();
    const userSession = useAuthUser<IUserSession>();

    toast.dismiss("AdminCertificateLicenseInReviewAlert");
    toast.dismiss("AdminCertificateAlert");

    const validateLoggedInUser = async () => {
        if (!isAuthenticated) return;

        const authTokenExpiry = storage.getAuthTokenExpirationTime();
        if (authTokenExpiry.isSameOrAfter(AppFunctions.CurrentDateTimeInMoment())) return;

        const refreshTokenExpiry = storage.getRefreshTokenExpirationTime();
        if (refreshTokenExpiry.isSameOrAfter(AppFunctions.CurrentDateTimeInMoment())) {
            await refreshToken();
            navigate(0);
            return;
        }

        storage.setReturnUrl(pathname);
        signOut();
        return navigate("/login");
    };

    validateLoggedInUser();

    const checkUserToken = () => {
        if (!isAuthenticated) {
            storage.setReturnUrl(pathname);
            setIsLoggedIn(false);
            return navigate("/login");
        }
        setIsLoggedIn(true);
    };

    const isCompanyUser = () => {
        if (userSession?.isCompanyUser) {
            return navigate("/invalid-url");
        }
    };

    useEffect(() => {
        checkUserToken();
        isCompanyUser();
    }, [isLoggedIn]);

    return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};


export default ProtectedRoute;

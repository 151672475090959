import { CalenderEnum } from "../../constants/CalenderEnum";
import { useState } from "react";
import { ToolbarProps } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";



function CustomCalendarToolbar(props: ToolbarProps<any, any>) {
    const [viewState, setViewState] = useState(props.view);

    const getCustomToolbar = () => {

        const goToWeekView = () => {
            props.onView("week");
            setViewState("week");
        };

        const goToMonthView = () => {
            props.onView("month");
            setViewState("month");
        };

        const goToBack = () => {
            props.onNavigate("PREV", props.date);
        };

        const goToNext = () => {
            props.onNavigate("NEXT", props.date);
        };

        const goToToday = () => {
            const now = new Date();
            props.date.setMonth(now.getMonth());
            props.date.setFullYear(now.getFullYear());
            props.date.setDate(now.getDate());
            props.onNavigate("TODAY");
        };

        const month = () => {
            return (
                <div className="rbc-btn-group calnder-btn">
                    <button className="pre-iocn-btn" type="button" onClick={goToBack}>
                        <span className="prev-icon">&#8249;</span>
                    </button>
                    <button type="button" onClick={goToToday}>
                        <span className="next-icon">Today</span>
                    </button>
                    <button className="pre-iocn-btn" type="button" onClick={goToNext}>
                        <span className="next-icon">&#8250;</span>
                    </button>
                    <span className="rbc-toolbar-label">
                        <i className="far"></i> <span>{viewState === CalenderEnum.Month ? props.label.split(" ").join(", ") : props.label}</span>
                    </span>
                </div>
            );
        };

        return (
            <div className="rbc-toolbar">
                <div className="col-sm-3">
                    {month()}
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-3 calendar-btn-position text-right" >
                    <span className="rbc-btn-group">
                        <button type="button" className={viewState === "week" ? "rbc-active" : ""} onClick={goToWeekView}>
                            <ViewColumnOutlinedIcon sx={{ fontSize: '1rem', marginRight: '4px' }} />
                            <span className="label-filter-off">Week</span>
                        </button>
                        <button type="button" className={viewState === "month" ? "rbc-active" : ""} onClick={goToMonthView}>
                            <CalendarMonthOutlinedIcon sx={{ fontSize: '1rem', marginRight: '4px' }} />
                            <span className="label-filter-off">Month</span>
                        </button>
                    </span>
                </div>
            </div>
        );
    };

    return <>{getCustomToolbar()}</>;
}

export default CustomCalendarToolbar;
import ColumnDefinition from "components/controls/DataGrid/models/ColumnDefinition";
import DataGridActionsCellItem from "components/controls/DataGrid/models/DataGridActionsCellItem";
import { z } from "zod";
import { FieldMappingModel } from "../project/import-export/ImportHelper";
import { dateRangeSchema } from "components/controls/DateRangePickerField";
import { Moment } from "moment";

export const QueryBuilderSchema = z.object({
    id: z.string().optional(),
    objectId: z.string().optional(),
    moduleName: z.string().optional(),
    name: z.string().min(1, "Name is required"),
    selectClause: z.string().nullish(),
    whereClause: z.string().nullish(),
});

export type QueryBuilderSchemaType = z.infer<typeof QueryBuilderSchema>;

export type QueryModel = QueryBuilderSchemaType & {
    createdDate?: string,
    updatedDate?: string,
}

export type QueryBuilderResultModel = {
    id: string,
    objectId: string,
    moduleName: string,
    name: string,
    selectClause?: string,
    whereClause?: string,
    result?: any,
    mappings?: FieldMappingModel[]
}

export const queryBuilderColumns = (actions: DataGridActionsCellItem[]): ColumnDefinition[] => {
    return [
        { field: 'id', headerName: 'ID', type: 'number', hideable: false },
        { field: 'name', headerName: 'Name', type: 'string', width: 300 },
        { field: 'createdDate', headerName: 'Created Date', type: 'date', width: 120 },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            getActions: actions
        }
    ]
};

export const QueryFilterSchema = z.object({
    id: z.string().optional(),
    dateRange: dateRangeSchema
});

export type QueryFilterSchemaType = z.infer<typeof QueryFilterSchema>;

export type ExportCustomQueriesRequestModel = {
    fileType: string,
    clientAlias?: string,
    queryIds: string[],
    startDate?: Moment | null,
    endDate?: Moment | null
}
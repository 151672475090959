import { AppointmentInformationModel } from "models/facility/AppointmentInformationModel";
import { createSlice } from "@reduxjs/toolkit";

export const AppointmentStoreSlice = createSlice({
    name: "appointment",
    initialState: {} as AppointmentInformationModel,
    reducers: {
        AppointmentInformation: (state: AppointmentInformationModel, action) => {
            state.clientId = action.payload.clientId;
            state.projectId = action.payload.projectId;
            state.facilityId = action.payload.facilityId;
            state.timeWindowId = action.payload.timeWindowId;
            state.appointmentType = action.payload.appointmentType;
            state.slotStartTime = action.payload.slotStartTime;
            state.slotEndTime = action.payload.slotEndTime;
            state.isReschedule = action.payload.isReschedule;
            state.facilityInfo = action.payload.facilityInfo;
            state.isSlotFixed = action.payload.isSlotFixed;
            state.projectName = action.payload.projectName;
            state.appointmentStartTime = action.payload.appointmentStartTime;
            state.appointmentEndTime = action.payload.appointmentEndTime;
            state.customNoOfFieldTechPerClient = action.payload.customNoOfFieldTechPerClient;
            state.projectFacilityWorkItemIds = action.payload.projectFacilityWorkItemIds;
            state.isWorkItemLinked = action.payload.isWorkItemLinked!;
        }
    },
});

export const { AppointmentInformation } = AppointmentStoreSlice.actions;
import moment, { Moment } from "moment";
import { Constants } from "constants/Constants";
import { ValidatorTypeEnum } from "constants/ValidatorTypeEnum";
import { ControlType } from "models/ControlType";
import { ParserHelper } from "helpers//ParserHelper";
import { GridCellParams } from "@mui/x-data-grid-pro";


export class AppFunctions {

    public static IsNullOrUndefined(value: any | undefined): boolean {
        return (value === undefined || value === null || value.length === 0 || value === '')
    }

    public static IsNullOrWhiteSpace(value: string | undefined | '' | null): boolean {
        return (value === undefined || value === null || value.toString().trim() === '')
    }

    public static RegularExpressionMatched(value: string | undefined, regularExpression: RegExp | null): boolean {
        if (AppFunctions.IsNullOrWhiteSpace(value) || regularExpression === null)
            return false;

        return regularExpression!.test(value!);
    }

    public static RegularExpressionByParsedFunction(parseFunc: Function | undefined): RegExp | undefined {
        switch (parseFunc) {
            case ParserHelper.parsePhone:
                return Constants.RegexDigits;
            default:
                return undefined;
        }
    }

    public static PhoneNumberWithoutMasking(value: string | undefined): string | undefined {
        if (AppFunctions.IsNullOrWhiteSpace(value))
            return undefined;

        return value?.match(Constants.RegexDigits)?.join('');
    }

    public static GetErrors(validatorTypeEnum: ValidatorTypeEnum | null, props: ControlType): string | null {
        return AppFunctions.GetErrorValidatorTypeEnum(props, validatorTypeEnum);
    }

    private static GetErrorValidatorTypeEnum(props: ControlType, key: ValidatorTypeEnum | null): string | null {
        if (key === null)
            return null;

        const enumKey = Object.values(ValidatorTypeEnum)[key] as ValidatorTypeEnum;

        switch (key) {
            case ValidatorTypeEnum.requiredValidator:
            case ValidatorTypeEnum.blankSpaceValidator:
                return props.validationmessage?.[enumKey] ?? `${props.label} is required`;
            case ValidatorTypeEnum.minLengthValidator:
                return props.validationmessage?.[enumKey] ?? `${props.label} minimun lenght should be ${props.minLength}`;
            case ValidatorTypeEnum.regexValidator:
                return props.validationmessage?.[enumKey] ?? `Invalid ${props.label}`;
            case ValidatorTypeEnum.minRangeValidator:
                return props.validationmessage?.[enumKey] ?? `${props.label} is less than min range`;
            case ValidatorTypeEnum.maxRangeValidator:
                return props.validationmessage?.[enumKey] ?? `${props.label} is more than max range`;
            default:
                return 'Error';
        }
    }

    public static formatDecimals(value: string): string {
        return value.replace(/[^0-9]/g, '');
    }

    public static isAccessTokenExpired(accessTokenExpiry: number, refreshInterval: number): boolean {
        return accessTokenExpiry - Date.now() <= refreshInterval * 1000;
    }

    public static sortArrayObjects(object1: any, object2: any, key: string): number {
        if (object1[key] < object2[key]) {
            return -1;
        }
        if (object1[key] > object2[key]) {
            return 1;
        }
        return 0;
    }

    public static isPositiveNegativeNumber(value: string | ''): boolean {
        const isValidNumber = Constants.RegexPositiveNegativeNumbers.test(value);
        return isValidNumber;

    }

    public static isPositiveNumber(value: string | ''): boolean {
        const isValidNumber = Constants.RegexPositiveNumbers.test(value);
        return isValidNumber;
    }

    public static isValidZipCodeFormat(value: string | ''): boolean {
        const isValidZipCode = Constants.RegexZipCode.test(value);
        return isValidZipCode;
    }

    public static isValidState(value: string | ''): boolean {
        const isValidState = Constants.RegexState.test(value);
        return isValidState;
    }

    public static isValidPostalCode(value: string | ''): boolean {
        const isValidZipCode = Constants.RegexPostalCode.test(value);
        return isValidZipCode;
    }

    public static isValidEmail(value: string | ''): boolean {
        if (AppFunctions.IsNullOrWhiteSpace(value))
            return true;

        const isValidZipCode = Constants.RegexEmail.test(value);
        return isValidZipCode;
    }

    public static GetDate(value: string | undefined | ''): Moment | undefined {
        if (AppFunctions.IsNullOrWhiteSpace(value))
            return undefined;
        return moment(value!)
    }

    public static GetDateDaysDiff(startDate: string | undefined | '', endDate: string | undefined | ''): number | undefined {
        if (AppFunctions.IsNullOrWhiteSpace(startDate) || AppFunctions.IsNullOrWhiteSpace(endDate))
            return undefined;

        return AppFunctions.GetDate(endDate)!.diff(AppFunctions.GetDate(startDate), 'days')
    }

    public static GetDateMonthsDiff(startDate: string | undefined | '', endDate: string | undefined | ''): number | undefined {
        if (AppFunctions.IsNullOrWhiteSpace(startDate) || AppFunctions.IsNullOrWhiteSpace(endDate))
            return undefined;

        return AppFunctions.GetDate(endDate)!.diff(AppFunctions.GetDate(startDate), 'months')
    }

    public static CurrentDateTimeISOFormat(): string {
        return AppFunctions.CurrentDateTimeInMoment().toISOString()
    }

    public static CurrentDateTimeInMoment(): Moment {
        return moment(new Date())
    }

    public static GetTimeFromDate(value: string, string = 'MM/DD/YYYY hh:mm:ss'): string | undefined {
        return AppFunctions.GetDate(value)?.format('hh:mm:ss')
    }

    public static ConvertToDateTime(value: any, dateFormat: string = 'MM/DD/YYYY hh:mm:ss'): string | undefined {
        return AppFunctions.GetDate(value)?.format(dateFormat);
    }

    public static ConvertToDateTimeMeridian(value: any, dateFormat: string = 'MM/DD/YYYY hh:mm A'): string | undefined {
        return AppFunctions.GetDate(value)?.format(dateFormat);
    }

    public static GetMomentDateFromDateTimeString(date: string, time: string): any {
        return moment(date + ' ' + time).utc();
    }

    public static ConvertToDate(value: string): string | undefined {
        return AppFunctions.GetDate(value)?.format('MM/DD/YYYY');
    }

    public static ConvertMomentLocalIntoYYYYMMDD(value: string): string {
        return moment.utc(value).local().format("YYYY-MM-DD");
    }

    public static ConvertStringToDate(value: string): Date {
        return new Date(value);
    }

    public static ConvertGetTimeToDate(value: Date): Date {
        return new Date(value.getTime());
    }

    public static ConvertToCurrency(value: number, precision: number = 2): string {
        const formattedValue = value.toFixed(precision);
        return ('$ ' + formattedValue);
    }

    public static IsDateWithinRange(startDate: Date, endDate: Date, date: Date): boolean {
        return date >= startDate && date <= endDate;
    }

    public static ConvertTimeTo12HoursFormat(startTime: string): string {
        const initialTime: Date = new Date(`01/01/2000 ${startTime}`);
        const meridiem: string = initialTime.getHours() < 12 ? 'am' : 'pm';
        const addedHours12: number = initialTime.getHours() > 12 ?
            initialTime.getHours() - 12 : initialTime.getHours();
        const displayHour: number = addedHours12 === 0 ?
            12 : addedHours12;
        return `${displayHour} : 
            ${(initialTime.getMinutes() < 10 ? '0' + initialTime.getMinutes() : initialTime.getMinutes())}  ${meridiem}`;
    }

    public static GetTimeWindowEndTime(startTime: string, slotDuration: number, numberOfSlots: number): string {
        const totalTimeWindow: number = slotDuration * numberOfSlots;
        const initialTime: Date = new Date(`01/01/2000 ${startTime}`);
        const endTimeDate: Date = new Date(initialTime.getTime() + totalTimeWindow * 60 * 60 * 1000);
        const meridiem: string = endTimeDate.getHours() < 12 ? 'am' : 'pm';
        const addedHours12: number = endTimeDate.getHours() > 12 ?
            endTimeDate.getHours() - 12 : endTimeDate.getHours();
        const displayHour: number = addedHours12 === 0 ?
            12 : addedHours12;

        return `${displayHour} : 
            ${(endTimeDate.getMinutes() < 10 ? '0' + endTimeDate.getMinutes() : endTimeDate.getMinutes())}   ${meridiem}`;
    }

    public static AddHoursToTime(time: string, hoursToAdd: number): string {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        date.setHours(date.getHours() + hoursToAdd);
        return `${AppFunctions.PadZero(date.getHours())}:${AppFunctions.PadZero(date.getMinutes())}`
    }

    public static PadZero(num: number): string {
        return num.toString().padStart(2, '0');
    }

    public static IsNavigateCellClick(newSelection: GridCellParams, gridColumns: string[], onEditHandler: Function, gridColumnIndex: number = 0, multiColumnClickable: boolean = false) {
        if (gridColumns.length > 0 && (multiColumnClickable || newSelection.field === gridColumns[gridColumnIndex])) {
            onEditHandler(newSelection)();
        }
    }

    public static IsFirstNavigateCellClick(newSelection: GridCellParams, gridColumns: string[]) {
        if (gridColumns.length > 0 && newSelection.field === gridColumns[0]) {
            return true;
        }
        return false;
    }

    public static IsSpecificCellClick(newSelection: GridCellParams, gridColumns: string[], onEditHandler: Function, cellIndex: number) {
        if (gridColumns.length > 0 && newSelection.field === gridColumns[cellIndex]) {
            onEditHandler(newSelection)();
        }
    }

    public static GetBusinessDatesCount(start: string, end: string): number {
        const startDate: Date = new Date(start);
        const endDate: Date = new Date(end);
        let count = 0;
        const currentDate = new Date(startDate.getTime());
        while (moment(currentDate).isSameOrBefore(moment(endDate), 'dates')) {
            const dayOfWeek = currentDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return count;
    }

    public static GetWorkingBusinessDatesCount(start: string, end: string, listOfHoliday: Date[], workingDays: number[]): number {
        const startDate: Date = new Date(start);
        const endDate: Date = new Date(end);
        let count = 0;
        const currentDate = new Date(startDate.getTime());
        while (moment(currentDate).isSameOrBefore(moment(endDate), 'dates')) {
            const dayOfWeek = currentDate.getDay();
            const isHoliday = listOfHoliday.find(x => moment(x).isSame(moment(currentDate), 'day'));
            if (workingDays.includes(dayOfWeek) && isHoliday == undefined) {
                count++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return count;
    }

    public static SplitDateFromDateTime(value: string): string | undefined {
        return value.split('T')[0]!;
    }

    public static FormatTimeInHHMM(value: any): string | undefined {
        return moment(value).format('hh:mm a');
    }

    public static FormatDateMMMDD(value: Date): string | undefined {
        return moment(value).format('MMM DD');
    }

    public static FormatDateToYYYYMMDD(value: Date): string | undefined {
        return moment(value).format("YYYY-MM-DD");
    }

    public static AddOneMoreDayAndFormatDateToYYYYMMDD(value: string): Date {
        return new Date(moment(value).parseZone().local().add(1, 'day').format('MMMM D YYYY'));
    }

    public static ConvertToLocalTime(value: any): Moment {
        return moment(value).parseZone().local();
    }

    public static ConvertToUniversalTime(value: any): Moment {
        const timezoneOffset = new Date().getTimezoneOffset() / 60;
        return moment(value).utc().add(-timezoneOffset, 'hours');
    }

    public static ConvertSlotDurationIntoMinutes(duration: string): number {
        return (parseFloat(duration!) * 60);
    }

    public static ConvertSlotDurationForCalendarDisplay(slotDuration: string): string {
        let slotDurationInMinutes: number = parseInt(slotDuration);
        let hours: number = 0;
        let message: string = '';

        while (slotDurationInMinutes >= 60) {
            slotDurationInMinutes -= 60;
            hours++;
        }
        if (hours > 0) {
            message = ` ${hours}hrs`;
        }
        if (slotDurationInMinutes > 0 && slotDurationInMinutes < 60) {
            message += ` ${slotDurationInMinutes}mins `;
        }

        return message;
    }

    public static IsValidDate(value: any | undefined): boolean {
        return (value !== undefined && moment(value).isValid());
    }

    public static GetInitials = (inputString: string): string => {
        const words = inputString.trim().split(" ");
        let initials = "";
        for (let word of words) {
            initials += word.charAt(0).toUpperCase();
            if (initials.length === 2) {
                break;
            }
        }
        return initials;
    };

    public static ConvertToLocalTimeFormat(value: any, dateFormat: string = 'YYYY-MM-DD hh:mm:ss'): string {
        return moment(value).parseZone().local().format(dateFormat);
    }

    public static isValidFax(value: string | ''): boolean {
        const isValidNumber = Constants.RegexPositiveNumbers.test(value) || value === '';
        return isValidNumber;
    }

    public static AddMonthToCurrentYearAndMonth(selectedMonth: number, monthsToAdd: number): string {
        const calculateMonth = moment().month(selectedMonth - 1).add(monthsToAdd, 'months').date(1);
        return moment(calculateMonth).toISOString();
    }

    public static ConvertToMomentMothYear(value: string): string | undefined{
        return AppFunctions.GetDate(value)?.format('MM/YYYY')
    }
    
    public static IsPastDate(value: string): boolean {
        return moment(value).isBefore(moment().startOf('day'), 'day')
    }
    public static IsPastDateForGauges(value: string): boolean {
        return moment(value).isBefore(moment().startOf('day').clone().subtract(1, 'years'))
    }
}
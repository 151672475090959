import { AppFunctions } from "helpers/AppFunctions";
import NameAvatar from "components/NameAvatar";
import { IdLabelAvatarEmailLookup, IdLabelAvatarLookup } from "models/IdLabel";
import { Button, ListItem, Popover, Switch } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

type CustomerProps = {
    data: string[],
    options: IdLabelAvatarEmailLookup[],
    noOfFieldTechPerClient: number,
    isCapabilityAssignmentChecked: boolean,
    onChange?: (selectedItem: IdLabelAvatarEmailLookup[], isCapabilityAssignmentChecked: boolean) => void,
}
export const CustomGridSearchDropdown = (props: CustomerProps) => {

    const [selectedValue, setSelectedValue] = useState<IdLabelAvatarEmailLookup[]>([]);
    const [options, setOptions] = useState<IdLabelAvatarEmailLookup[]>(props.options);
    const [isMultipleFieldTech, setIsMultipleFieldTech] = useState<boolean>(false);
    const [isCapabilityAssignment, setIsCapabilityAssignment] = useState<boolean>
        (props.isCapabilityAssignmentChecked);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        const intialValue = props.options.filter(x => props.data.includes(x.id));
        setSelectedValue(intialValue);
        if (props.noOfFieldTechPerClient > 1)
            setIsMultipleFieldTech(true);
        if (props.data.length === props.noOfFieldTechPerClient)
            setIsCapabilityAssignment(true);
    }, [])

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const term = event.target.value;
        setSearchTerm(term);
        if (AppFunctions.IsNullOrWhiteSpace(term)) {
            setOptions(props.options);
            return;
        }

        const filteredOption = props.options.filter(option =>
            option.label.toLowerCase().includes(term.toLowerCase())
        );

        setOptions(filteredOption);
    }

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleCellClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (isMultipleFieldTech) {
            const intialValue = props.options.filter(x => props.data.includes(x.id));
            setSelectedValue(intialValue);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleListItemSelect = (item: IdLabelAvatarEmailLookup) => {

        setSelectedValue([item]);
        props.onChange?.([item], true);
        setAnchorEl(null);
    }

    const handleMultiListItemSelect = (item: IdLabelAvatarEmailLookup) => {
        if (selectedValue.length < props.noOfFieldTechPerClient) {
            const isSelected = selectedValue.some(x => x.id === item.id);
            if (isSelected) {
                setSelectedValue((prev) => prev.filter(x => x.id !== item.id));
                return;
            }
            setSelectedValue([...selectedValue, item]);
            setSearchTerm('');
        }
    }

    const handleRemoveSelected = (id: string) => {
        setSelectedValue((prev) => prev.filter(item => item.id !== id));
    }

    const handleMultipleFieldTechAssignment = () => {
        props.onChange?.(selectedValue, isCapabilityAssignment);
        setAnchorEl(null);
    }

    const handleSwitchChange = () => {
        setIsCapabilityAssignment(!isCapabilityAssignment);
    };

    return (
        <Fragment>

            <div > {!AppFunctions.IsNullOrUndefined(selectedValue) ? (
                selectedValue.length === 1 ? (
                    <div
                        className="assigned-user-container"
                        onClick={handleCellClick}
                        aria-describedby={id} >
                        <NameAvatar className="small-avatar"
                            name={selectedValue[0].avatarName ?? ""}
                            hasAvatarName={true} />
                        <div className="assigned-user-label">{selectedValue[0].label}</div>
                    </div>) : (
                    <div
                        onClick={handleCellClick}
                        aria-describedby={id}>
                        {selectedValue?.map((item, index) => (
                            <div className="assigned-user-container">
                                <NameAvatar
                                    className="small-avatar"
                                    name={item?.avatarName ?? ""}
                                    hasAvatarName={true} />
                                <div className="assigned-user-label">{item.label}</div>
                            </div>))}

                    </div>)) :
                (<div
                    onClick={handleCellClick}
                    aria-describedby={id}>
                    <span>Not assigned</span>

                </div>)}</div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                {!isMultipleFieldTech && <><div className='grid-search-button-container'>
                    <SearchIcon className='search-icon'></SearchIcon>
                    <input
                        autoFocus
                        type="search"
                        placeholder={"Search..."}
                        className={'form-control'}
                        value={searchTerm}
                        autoComplete="off"
                        onChange={handleSearchInputChange}
                        spellCheck={false}
                    ></input>
                </div>
                    <div className="option-popover"> {options.map((option) => (
                        <ListItem className="grid-option-container"
                            onClick={() => handleListItemSelect(option)}>
                            <NameAvatar className="small-avatar"
                                name={option?.avatarName ?? ""}
                                hasAvatarName={true} />
                            <div className="option-label">
                                <div>{option.label}</div>
                                <div className="option-label-email">{option.email}</div>
                            </div>
                        </ListItem>
                    )
                    )}</div></>}

                {isMultipleFieldTech &&
                    <div>
                        <div className="option-popover">
                            <div className="multiple-selected-option-container">
                                {selectedValue.map((x) => (<div
                                    className="multiple-selected-label">{x.label}
                                    <CloseIcon
                                        fontSize="small"
                                        onClick={() => handleRemoveSelected(x.id)} /></div>))}
                                <input
                                    autoFocus
                                    type="search"
                                    placeholder={""}
                                    value={searchTerm}
                                    autoComplete="off"
                                    spellCheck={false}
                                    onChange={handleSearchInputChange}
                                ></input>

                            </div>
                            {options.map((option) => (
                                <ListItem className="grid-option-container"
                                    onClick={() => handleMultiListItemSelect(option)}>
                                    <NameAvatar className="small-avatar"
                                        name={option?.avatarName ?? ""}
                                        hasAvatarName={true} />
                                    <div className="option-label">
                                        <div>{option.label}</div>
                                        <div className="option-label-email">{option.email}</div>
                                    </div>

                                </ListItem>
                            )
                            )}
                        </div>
                        {selectedValue.length > 0 && <div className="text-center">
                            <div className="capability-container">
                                <Switch size="small"
                                    checked={isCapabilityAssignment}
                                    onChange={handleSwitchChange}
                                ></Switch>  Capability Based Assignment
                            </div>
                            <Button
                                onClick={handleMultipleFieldTechAssignment}
                            >Assign</Button></div>}
                    </div>}
            </Popover>
        </Fragment>
    );
};
export default CustomGridSearchDropdown;
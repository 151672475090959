import AddSharpIcon from "@mui/icons-material/AddSharp";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
    gridColumnDefinitionsSelector,
    GridFilterItem,
    gridFilterModelSelector,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid-pro";
import { GUID } from "helpers/GuidFunction";
import { IdLabelActiveCodeLookup, IdLabelLookup } from "models/IdLabel";
import { useState } from "react";
import IDataGridProps from "components/controls/DataGrid/models/IDataGridProps";
import { FilterTagPopover } from "./FilterTagPopover";

const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: IdLabelActiveCodeLookup) => option.label,
});

const FilterTag = ({
    filterTags,
    dataGridServiceType,
    gridFilterInitialState,
    cellFormattedLookups
}: IDataGridProps): JSX.Element => {
    const apiRef = useGridApiContext();
    const gridFilterModel = useGridSelector(apiRef, gridFilterModelSelector);
    const columnDefinitionsSelector = useGridSelector(
        apiRef,
        gridColumnDefinitionsSelector
    );
    const actionAndNonHideableColumns = columnDefinitionsSelector.filter(
        (x) => (x.type === "actions" || x.type === 'customAction') || (x.hideable === false && x.cellClassName !== 'cursor-pointer')
    );
    const fields = actionAndNonHideableColumns.map((x) => x.field);
    const headers = actionAndNonHideableColumns.map((x) => x.headerName!);
    const columns = ["ID", "id", "Actions", "ACTIONS", "__reorder__", "__check__", 'rowIndex', 'customAction', ...fields, ...headers];

    const excludedActionAndNonHideableColumns = columnDefinitionsSelector.filter(
        (x) => (x.type !== "actions" || x.type !== 'customAction') && (x.cellClassName === 'cursor-pointer' || x.hideable !== false)
    );

    const initialFilterAddedTags = (reset?: boolean): IdLabelLookup[] => {
        const initialFilterColumnFields = filterTags?.map(x => x.id) ?? []
        const items = reset ? gridFilterInitialState?.filterModel?.items ?? [] : gridFilterModel.items;
        const filterItems = items.filter(x => !initialFilterColumnFields?.includes(x.field))
        const preFilterTags = filterItems?.map(function (x: GridFilterItem) {
            return {
                id: x.field,
                label: columnDefinitionsSelector.find(column => column.field === x.field)?.headerName ?? '',
            };
        }) ?? [];
        const gridFilterTags = [...(filterTags ?? []), ...preFilterTags]

        const existingColumnFields = excludedActionAndNonHideableColumns.map(x => x.field)
        const notAvailableFilterTagInGridColumns = gridFilterTags.filter(function (obj) { return existingColumnFields.indexOf(obj.id) == -1; });
        return gridFilterTags.filter(x => !notAvailableFilterTagInGridColumns.find(f => f.id === x.id));
    }

    const [selectedTag, setSelectedTag] = useState<IdLabelActiveCodeLookup | null>();
    const [tags, settags] = useState<IdLabelActiveCodeLookup[]>(
        initialFilterAddedTags().map(function (x: IdLabelLookup) {
            return { id: x.id.toLowerCase(), label: x.label, isActive: false, code: x.id };
        }) ?? []
    );

    apiRef?.current?.subscribeEvent('stateChange', (event: any) => {
        if (event.reset) {
            const intialTags = (initialFilterAddedTags(true).map(function (x: IdLabelLookup) {
                return { id: x.id.toLowerCase(), label: x.label, isActive: false, code: x.id };
            }) ?? []);
            const opts = tags.filter(x => !intialTags.map(y => y.code).includes(x.code))

            settags([...intialTags]);

            const arrayWithDuplicates = [...options, ...opts]
            const distinctArray = arrayWithDuplicates
                .filter((n, i) => arrayWithDuplicates.indexOf(n) === i)
                .sort((a, b) => (a.label < b.label ? -1 : 1));
            setOptions(distinctArray);
        }
    });

    const [removedOptions] = useState<string[]>([
        ...columns,
        ...tags.map((y) => y.id),
    ]);

    const [options, setOptions] = useState<IdLabelActiveCodeLookup[]>(
        excludedActionAndNonHideableColumns
            .filter(
                (x) =>
                    !removedOptions
                        .map((v) => v.toLowerCase())
                        .includes(x.field.toLowerCase())
            )
            .map(function (x: any) {
                return {
                    id: x.field.toLowerCase(),
                    label: x.headerName,
                    isActive: false,
                    code: x.field
                };
            }).sort((a, b) => (a.label < b.label ? -1 : 1)) ?? []
    );

    const [showFilterTagsOption, setShowFilterTagsOption] =
        useState<boolean>(false);

    const DataGridAutocomplete = () => {
        return (
            <Autocomplete
                id="filter"
                options={options}
                getOptionLabel={(option) => option.label}
                filterOptions={filterOptions}
                onChange={(event: any, option: IdLabelActiveCodeLookup | null) => {
                    if (option === null) return;
                    const selectedOption = options.find((x) => x.id === option.id)!;
                    setSelectedTag(selectedOption);
                    settags((preState) => [
                        ...preState,
                        {
                            id: selectedOption.id.toLowerCase(),
                            label: selectedOption.label,
                            code: selectedOption.code,
                            isActive: false
                        },
                    ]);
                    setOptions((pre) => [
                        ...pre.filter((x) => x.id !== selectedOption.id),
                    ]);
                    setShowFilterTagsOption(false);
                }}
                sx={{ width: 250, textAlign: 'center' }}
                className="filter-autocomplete common-fliterseach"
                onBlur={() => {
                    setShowFilterTagsOption(false);
                }}
                openOnFocus
                renderInput={(params) => <TextField
                    autoFocus
                    {...params} placeholder='Search' />}
            />
        );
    };

    const handleCrossBtnIcon = (filterTag: any) => {
        settags((preState) => [...preState.filter((x) => x.id !== filterTag.id)]);
        setOptions((pre) => [
            ...pre,
            { id: filterTag.id, label: filterTag.label, code: filterTag.code, isActive: false },
        ]);

        const filterItem = gridFilterModel.items.find(t => t.field == filterTag.code)
        if (filterItem)
            apiRef.current.deleteFilterItem(filterItem!)
    };

    return (
        <div className="MuiDataGrid-filterTags">
            {tags.map((filterTag, key) => {
                return (
                    <div className="fliter-tag-wrapper" key={key}>
                        <FilterTagPopover tag={filterTag} selectedTag={selectedTag!} dataGridServiceType={dataGridServiceType}
                        cellFormattedLookups={cellFormattedLookups}
                        ></FilterTagPopover>
                        {!filterTags
                            ?.map((x) => x.id.toLowerCase())
                            .includes(filterTag.id) && (
                                <CloseTwoToneIcon
                                    className="filter-tag-close-icon"
                                    key={GUID.NewGUID()}
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => handleCrossBtnIcon(filterTag)}
                                />
                            )
                        }
                    </div>
                );
            })}
            {
                <div className="filter-more-button-wrapper">
                    {showFilterTagsOption ? (
                        <DataGridAutocomplete></DataGridAutocomplete>
                    ) : (<>
                        {options.length > 0 && <button
                            aria-label="Add Tag"
                            className="more-btn"
                            data-focuszone=""
                            data-is-focusable="true"
                            tabIndex={0}
                            type="button"
                            onClick={() => {
                                setShowFilterTagsOption(true);
                            }}
                        >
                            <span className="bolt-button-text body-m">
                                More <AddSharpIcon className="plus-icon" />
                            </span>
                        </button>}</>
                    )}
                </div>
            }
        </div>
    );
};

export default FilterTag;
import { ProjectService } from "common/services/client/ProjectService";
import { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { ProjectInformationModel } from "pages/client/projects/ProjectInformation";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
 

const ProjectStatus = () => {
  const [statusLabel, setStatusLabel] = useState("");
  const projectInformation: ProjectInformationModel =
    useSelector((state: RootState) => state.projectInformation);
  const getProjectStatusLabel = async () => {
    await ProjectService.getProjectDetailByIdAsync(params.projectId!).then(
      (projectData) => {

        setStatusLabel(projectData.projectInfo.statusLabel);
      }
    );
  };

  
  const params = useParams();
  useEffect(() => {
    (async () => await getProjectStatusLabel())();
  }, [params.projectId]);

  const ProjectStatusClass = (): string => {
    let activeClass: string = '';
    switch (projectInformation.statusLabel ?? statusLabel) {
        case "Active":
            activeClass = 'status-blue-tag';
            break;
        case "Pending":
            activeClass = 'status-grey-tag';
            break;
        case "Completed":
            activeClass = 'status-green-tag';
            break;
        case "Cancelled":
              activeClass = 'status-red-tag';
              break;
        default:
            activeClass = 'default-status-tag';
            break;
    }
    return activeClass;
}

  return (
      <span className={`status-tag-facility text-center text-uppercase ${ProjectStatusClass()}`} > 
      {projectInformation.statusLabel ?? statusLabel}
    </span>
  );
};

export default ProjectStatus;
